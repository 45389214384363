.home-page {
    .text-zone {
        position: absolute;
        left: 10%;
        top: 50%;
        transform: translateY(-50%);
        width: 40%;
        max-height: 90%;
    }

    h1 {
        color:#fff;
        font-size: 53px;
        margin: 0;
        font-family: "Coolvetica";
        font-weight: 400;

        img {
            width: 40px;
            margin-left: 20px;
            margin-bottom: -5px;
            opacity: 0;
            height: auto;
            animation: rotateIn 1s linear both;
            animation-delay: 1.4s;
        }
    }

    h2 {
        color: #8d8d8d;
        margin-top: 10px;
        font-weight: 400;
        font-size: 13px;
        font-family: "Quicksand";
        letter-spacing: 3px;
        animation: fadeIn 1s 1.8s backwards;
        margin-bottom: 20px;
    }

    .contact-button {
        color:#ED7D31;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: "Quicksand";
        font-weight: 1000;
        text-decoration: none;
        padding: 10px 18px;
        margin-left: 10px;
        border: 2px solid #ED7D31;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;
        // width: 150px;

        &:hover {
            background: #ED7D31;
            color: #333;
        }
    }

    .flat-button {
        color:#ED7D31;
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 4px;
        font-family: "Quicksand";
        font-weight: 1000;
        text-decoration: none;
        padding: 10px 18px;
        border: 2px solid #ED7D31;
        float: left;
        animation: fadeIn 1s 1.8s backwards;
        white-space: nowrap;

        &:hover {
            background: #ED7D31;
            color: #333;
        }
    }
}

@media screen and (max-width: 1200px){
    .tags,
    .home-page h1::before,
    .home-page h1::after{
        display: none;
    }

    

    .home-page {

        h1 {
            font-size: 45px;
        }

        h2 {
            font-size: 0px;
        }

        .text-zone {
            position: initial;
            width: 100%;
            transform: none;
            padding: 10px;
            box-sizing: border-box;
        }

        .flat-button {
            float: none;
            position: relative;
            display: block;
            margin: 20px auto 0 auto;
            width: 110px;

        }

        .contact-button {
            float: none;
            position: relative;
            display: block;
            margin: 20px auto 0 auto;
            width: 155px;

        }

        .logo-container {
            position: relative;
            width: 100px;
            height: auto;
            top: 50px;
            right: 0;
            box-sizing: border-box;
            margin: auto;
            left: 0; 
        }
        svg {
            position: absolute;
            top: auto;
            right: auto;
            bottom: auto;
            left: 0;
            margin: auto;
        }
        .logo{
            
            position: relative;
            padding-top: 20%;
            // left: 0;
            width: 0%;
            transform: translate(-50%);
            height: 20%;
            overflow: visible;
            scale: 75%;
            
        }
    }
}