.portfolio-page {

    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;

    // .portfolio-page::-webkit-scrollbar {
    //     display: none;
    // }
      
    h1.page-title{
        margin-left: 50px;
        margin-top: 100px;

    }

    .images-container{
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        padding-bottom: 100px;
        padding-left: 50px;
    }

    .image-box {
        position: relative;
        flex: 1 1 30%;
        height: 300px;
        overflow: hidden;
        border-radius: 10px;
        max-width: calc(35% - 10px);
        
        .portfolio-image{
            position: absolute;
            z-index: 2;
            width: 100%;
            height: 100%;
            object-fit: center;
        }
        .content {
            position: absolute;
            width: 100%;
            z-index: 3;
            padding: 10px 20px 0px 20px;
            bottom: -70px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            //---COTTON CANDY COLOUR---//
            // background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
            background: linear-gradient(-45deg, #181818, #ED7D31, #181818, #ED7D31,#fff);
            background-size: 400% 400%;
            animation: gradient 15s ease infinite;
            height: 24vh;
        }
        
        @keyframes gradient {
            0% {
                background-position: 0% 50%;
            }
            50% {
                background-position: 100% 50%;
            }
            100% {
                background-position: 0% 50%;
            }
        }  

        .title {
            margin-bottom: 0;
            margin-top: 0;
            color: #fff;
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
        }

        .description {
            font-size: 14px;
            margin-top: 5px;
            margin-bottom: 5px;
            color:#fff;
            font-weight: 700;
        }

        .btn {
            margin-top: 10px;
            margin-bottom: 10px;
            padding: 0 23px;
            height: 40px;
            line-height: 34px;
            border: 2px solid #ED7D31;
            border-radius: 4px;
            font-size: 14px;
            color: #fff;
            background: transparent;
            text-transform: uppercase;
            font-weight: 700px;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            cursor: pointer;

        }

        .btn:hover {
            transform: translateY(-3px);
            background-color: #ED7D31;
        }

        &:after {
            content: "";
            background: linear-gradient(180deg, #ED7D31, #181818);
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: 2;
            transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
            opacity: 0;
        }
        
        &:hover:after {
            opacity: 0.85;
        }

        &:hover .content {
            bottom: 0;
            background: transparent;
        }
    }

}

@media screen and (max-width: 1200px) {
    .container.portfolio-page {
        width: 100%;
        // flex-wrap: wrap;
        padding: 20px;
        box-sizing: border-box;
    
        h1.page-title {
            margin-left: 20px;
            margin-top: 20px;
        }
        .content{
            height: 21vh;
        }

        .image-box {
            flex: 1 1 50%;
            height: 20vh;
            max-width: calc(50% + 100px);
                
            .title {
                margin-bottom: 0;
                margin-top: 0;
                color: #fff;
                font-size: 13px;
                overflow: wrap;
                font-weight: 1000;
                line-height: 24px;
            }

            .description {
                font-size: 12px;
                font-weight: 500;
            }

            
            .btn {
                margin-top: 5px;
                margin-bottom: 5px;
                padding: 10px 10px 10px 10px;
                height: 30px;
                line-height: 5px;
                font-size: 12px;    
            }

        }   
    
    }
}

.portfolio-page::-webkit-scrollbar {
    display: none;
}