
*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

.logo{
.outline{

    svg{
        z-index: 1;
        
        position: absolute;
        top: 35%;
        left: 50%;
        transform: translate(25%);
        animation: fill 1s ease forwards 2s; 
    }
    
    path:nth-child(1){
        stroke-dasharray: 1185px;
        stroke-dashoffset: 1185px;
        animation: line-anim 5s ease forwards 2s;

        &:hover{
            opacity: 0;
            animation: fadeut 1s;
            color:#ED7D31;

            &::after{
                animation: line-anim 5s ease forwards 0s;
            }
        }

        
    }

    // path:nth-child(2){
    //     stroke-dasharray: 400px;
    //     stroke-dashoffset: 400px;
    //     animation: line-anim 4s ease forwards 5s;
    // }

    @keyframes line-anim {
        to {
            stroke-dashoffset: 0;
        }
    }

    @keyframes fill {
        from {
            fill: transparent;
        }
        to { 
            fill: #262626;
        }

    }
}

.background{
    svg{
        position: absolute;
        z-index: 0;
        // opacity: 0;
        top: 35%;
        left: 50%;
        transform: translate(32%, 5%);
        animation: fill 0.5s ease forwards 3.5s; 
    }
    
    path:nth-child(1){
        opacity: 0;
        offset-position: 20px;
        animation: anim1 3.7s ease forwards 4s;
        transform: translate(-1%) scale(95%);
    }
    path:nth-child(2){
        opacity: 0;
        transform: scale(95.5%);
        animation: anim1 3.7s ease forwards 4s;
    }

    @keyframes anim1 {
        from {
            opacity: 0;
        }
        to { 
            // fill: #ed7d31;
            opacity: 1;
        }
    }

}
}

@media screen and (max-width: 1200px){
    .logo{
        .outline{
            position: initial;
            width: 20vh;
            
        }
    }
}
    

    















// .logo-container {
//     z-index: 0;
//     width: 400px;
//     height: 609px;
//     position: absolute;
//     top: 0;
//     right: 15%;
//     bottom: 0;
//     left: auto;
//     margin: auto;
//     opacity: 0;

//     svg {
//         width: 100%;
//         height: auto;
//         bottom: 0;
//         transform: rotateZ(180deg) !important;
//     }

//     .solid-logo {
//         position: absolute;
//         top: auto;
//         right: auto;
//         bottom: auto;
//         left: 0;
//         margin: auto;
//         width: 100%;
//         opacity: 0;
//         transform: rotateZ(0deg);
//         z-index: 1;
//     }
// }

// .svg-container {
//     stroke: #ED7D31;
//     stroke-width: 1;
// }