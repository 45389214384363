.experience-page {
    padding-left: 100px;
    padding-right: 50px;
    width: calc(100% - 150px);
    position: initial;
    height: 100%;
    overflow: auto;
  }
  
  h1.page-title {
    margin-left: 50px;
    margin-top: 100px;
  }
  
  .cards-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 20px;
  }
  
  .card {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: inherit;
    border: 2px solid #cf773c;
    border-radius: 10px;
    // overflow: hidden;
    width: calc(100% - 50px);
    max-width: 550px;
    background: #333;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
  }
  
  .card-image {
    // flex: 100px;
    min-width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%; /* Circular frame for the logo */
    background-color: #f9f9f9;
    margin: 10px 0px 10px 20px;
  
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  
  .card-content {
    flex: 2 1 auto;
    padding: 20px;
  
    .company {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 10px;
      color: #fff;
    }
  
    .role {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 10px;
      color: #cf773c;
    }
  
    .summary {
      font-size: 14px;
      line-height: 1.6;
      color: #f9f9f9;
    }
    .date-location {
        font-size: 14px;
        font-weight: 500;
        color: #f9f9f9;
        margin-bottom: 10px;
        min-width: 120px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .card {
      flex-direction: column;
      align-items: center;
    }
  
    .card-image {
      width: 100px;
      height: 100px;
    }
  
    .card-content {
      padding: 15px;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 1200px) {
    .container.experience-page {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
  
    h1.page-title {
      margin-left: 20px;
      margin-top: 20px;
    }
  }
  