.contact-form {
    width: 100%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;
        
        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
            clear: both;
        }
    
        li.half {
            width: 200;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }
    
    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: #3e3e3e;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 0 20px;
        box-sizing: border-box;
    }
    
    textarea {
        width: 100%;
        border: 0;
        background: #3e3e3e;
        height: 50px;
        font-size: 16px;
        color: #fff;
        padding: 20px;
        min-height: 150px;
        box-sizing: border-box;
    }

    .flat-button {
        color:#ED7D31;
        background: 0 0;
        font: 11px;
        letter-spacing: 3px;
        font-family: "Quicksand";
        // font-weight: 1000;
        text-decoration: none;
        padding: 8px 10px;
        border: 2px solid #ED7D31;
        float: left;
        border-radius: 4px;

        &:hover {
            background: #ED7D31;
            color: #333;
        }
    }

    

}

.p {
    font-family: "Quicksand";
    font-size: 11px;
}

// .socials{
//     position: flex;
//     bottom: 20px;
//     width: 100%;
//     display: block;
//     padding: 0;    
//     text-align: center;
//     margin: 0;
//     .a{}
//     width: 100px;
//     height: 100px;
// }



@media screen and (max-width: 1200px){
    
    .map-wrap {
        float: none;
        width: 100%;
        height: 400px;
        margin: 0;
    }
    // .flat-button{
    //     width: 125px;
    // }
    
}